import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../models/decodedToken';

export const info = (token: string): DecodedToken | null => {
  try {
    const decoded = jwtDecode(token) as DecodedToken;
    return decoded;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
};
