import {
  categorization,
  commons,
  cpStationOptoins,
  info,
  Locales,
  machineList,
  measureUnits,
  menuCreator,
  menuType,
  menuUploader,
  productForm,
  productGroup,
  productSearchOptions,
  recipeBookType,
  storageUnit,
  timeUnit,
} from './types';

export const ptLocale: Locales = {
  [commons.LOADING]: 'Cargando',
  [commons.ACCEPT]: 'Aceptar',
  [commons.ADD]: 'Agregar',
  [commons.FILTERS]: 'Filtros',
  [commons.RETURN]: 'Retorna',
  [commons.FINISH]: 'Finalizar',
  [commons.START]: 'Começar',
  [commons.FINISHED]: 'Finalizado',
  [commons.NAME]: 'Nome',
  [commons.SKU]: 'SKU',
  [commons.STATUS]: 'Status',
  [commons.GROUP]: 'Grupo',
  [commons.DOES_NOT_APPLY]: 'Não aplica',
  [commons.DETAILS]: 'Mais detalhes',
  [commons.CATEGORY]: 'Categoria',
  [commons.CATEGORIES]: 'Categorias',
  [commons.STORES_IN]: 'Lojas em',
  [commons.TO]: 'a',
  [commons.IT_APPEARS_IN_THE_RECIPE_OF_THE]: 'Aparece na receita',
  [commons.THE_ITEM]: 'O artigo',
  [commons.FOR_THIS_REASON]: 'Por esse motivo',
  [commons.THERE_IS_NO_DATA_TO_DISPLAY]: 'Não há dados para exibir',
  [commons.IT_APPEARS_IN_THE_KITCHEN]: 'Appears in the kitchen',
  [commons.PRODUCT_TYPE]: 'Tipo de produto',
  [commons.CONTINUE]: 'Continuar',
  [commons.RECEIVE]: 'Recibir',
  [commons.PROGRESS]: 'Progreso',
  [commons.PRODUCT_NAME]: 'Nome de produto',
  [commons.UNIT]: 'Unidad',
  [commons.UNIT_IN_KDS]: 'Unidade em kds',
  [commons.UNITS]: 'Unidades',
  [commons.ACTIONS]: 'Acciones',
  [commons.GROUPS]: 'Grupos',
  [commons.INIT_DATE]: 'Data de início',
  [commons.FINAL_DATE]: 'Data final',
  [commons.SEARCH]: 'Procurar',
  [commons.EXPORT]: 'Exportar',
  [commons.CANCEL]: 'Cancelar',
  [commons.DELETE]: 'Retirar',
  [commons.SAVE]: 'Guardar',
  [commons.BACK]: 'Volver',
  [commons.STORES]: 'Lojas',
  [commons.STORE]: 'Loja',
  [commons.PRIORITY_STATUS]: 'Status de prioridade',
  [commons.COUNTRIES]: 'Paises',
  [commons.COUNTRY]: 'País',
  [commons.TYPE]: 'Modelo',
  [commons.SELECT_FILE]: 'Seleccionar archivo',
  [commons.KITCHENS]: 'Cozinhas',
  [commons.KITCHEN]: 'Cozinha',
  [commons.QUANTITY]: 'Quantia',
  [commons.TIME]: 'Tempo',
  [commons.QUANTITY_IN_KDS]: 'Quantidade em kds',
  [commons.LAST_MODIFIED]: 'Última modificação',
  [commons.LAST_UPDATE]: 'Última atualização',
  [commons.DETAIL_OF_THE_REASON]: 'Detalhe do motivo',
  [commons.REASON]: 'Razão',
  [commons.DATETIME]: 'Data / Hora',
  [commons.EMPTY_RESULT]: 'Nenhum resultado encontrado, tente novamente.',
  [commons.RECIPE_BOOK]: 'Recetario',
  [commons.SELECT_COUNTRY]: 'Escolha o país',
  [commons.PHOTO]: 'Foto',
  [commons.ITEM]: 'item',
  [commons.RESULT_SEARCH]: 'Resultados da busca por',
  [commons.OF]: 'de',
  [commons.FOR]: 'para',
  [commons.IN]: 'em',
  [commons.CORRECT]: 'Correto',
  [commons.ERROR]: 'erro',
  [commons.CITY]: 'Cidade',
  [commons.COPY]: 'Copiar',
  [commons.WITHOUT_OPTIONS]: 'sem opções',
  [commons.SELECT]: 'selecione',
  [commons.SELECT_ALL]: 'Selecionar tudo',
  [commons.SELECT_CITY]: 'Selecionar cidade',
  [commons.SELECT_PLATFORM]: 'Selecionar plataforma',
  [commons.PLATFORM]: 'Plataforma',
  [commons.PLATFORMS]: 'Plataformas',
  [commons.MENU]: 'Cardápio',
  [commons.SELECT_PAGE]: 'Selecione uma folha',
  [commons.SPECIFICATIONS]: 'Especificações',
  [commons.BRAND]: 'Marca',
  [commons.BRANDS]: 'Marcas',
  [commons.ASSEMBLED_PRODUCTION_IN]: 'Produção montada em',
  [commons.FINISHED_PRODUCTS]: 'Produtos Acabados',
  [commons.SUPPLIES_OR_PRODUCTIONS]: 'Insumos ou Produções',
  [commons.SEARCH_FOR_INPUTS_PRODUCTS_OR_PROTOCOL]: 'Pesquise por insumos, produtos ou protocolo',
  [commons.SWITCHING_INPUTS_ON_AND_OFF]: 'Ativando e desativando entradas',
  [commons.SELECT_A_KITCHEN]: 'Selecione uma cozinha',
  [commons.SELECT_THE_KITCHEN_TO_MONITOR]:
    'Selecione a cozinha para monitorar e gerenciar suprimentos, produtos e protocolos',
  [commons.ON_AND_OFF]: 'Ligado e desligado',
  [commons.PRODUCTS_RELATED_TO_THE_SUPPLIE]: 'Inserir produtos relacionados',
  [commons.BY_PROTOCOLS]: 'Por protocolos',
  [commons.PROTOCOLS]: 'Protocolos',
  [commons.SHUTDOWN_DUE_TO_PROTOCOLS]: 'Desligamento por protocolos',
  [commons.SHUTDOWN_BY_STOCKOUTS]: 'Desligamento por Stockouts',
  [commons.ON_]: 'Ligado',
  [commons.OFF_]: 'Desligado',
  [commons.BY_BRAND]: 'Por marca',
  [commons.ALL_TYPES]: 'Todos os tipos',
  [commons.STORES_AFFECTED_BY_THE_PRODUCT]: 'Lojas afetadas pelo produto',
  [commons.DISABLE]: 'Desabilitar',
  [commons.PRODUCT]: 'Produto',
  [commons.LOADING_PROTOCOLS]: 'Carregando protocolos',
  [commons.PRODUCTS]: 'Produtos',
  [commons.SUPPLIES]: 'Suprimentos',
  [commons.SELECT_BRAND]:
    'Selecione as marcas para as quais deseja conhecer o relatório do produto',
  [commons.SUPPLY]: 'Suprimento',
  [commons.OFF]: 'Desligados',
  [commons.ON]: 'Ligados',
  [commons.CURRENT_INVENTORY]: 'Inventário atual',
  [commons.ENABLED]: 'Ativado',
  [commons.DISABLED]: 'Desativado',
  [commons.PRIORITY_TURN_OFF]: 'Desligamento prioritário',
  [commons.PRIORITY_TURN_ON]: 'Ignição prioritária',
  [commons.PROTOCOL]: 'Protocolo',
  [commons.UNIT_OF_MEASUREMENT]: 'Unidade de medida',
  [commons.PRODUCTS_TURNED_OFF]: 'Produtos desligados',
  [commons.PRODUCTION_PERFORMANCE]: 'Desempenho de produção',
  [commons.STATE]: 'Estado',
  [commons.STATION]: 'Estação',
  [commons.USEFUL_LIFE]: 'Vida util',
  [commons.ASSEMBLY]: 'Producao',
  [commons.INVENTORY_GROUP]: 'Grupo de inventário',
  [commons.IVA_MAJOR_VALUE]: 'Maior valor de iva',
  [commons.TAX_GROUP_CODE]: 'Impostos sobre produtos acabados',
  [commons.IGV]: 'IGV',
  [commons.OVER_RECEIPT_CODE]: 'Código de recepção',
  [commons.GROUPS_ACCOUNTING_RECORD_IVA]: 'Grupo de contabilidade de IVA',
  [commons.STORAGE]: 'Armazenar',
  [commons.NCM_CODE]: 'Código NCM',
  [commons.STEP]: 'Etapa',
  [commons.CHECKPOINT]: 'Check point',
  [commons.SHOW_AS_COMBO]: 'Visível como combo',
  [commons.SHOW_AS_TOPPING]: 'Visível como topping',
  [commons.IS_VARIABLE_COMBO]: 'É uma combo variável',
  [commons.HIDDEN]: 'Escondido',
  [commons.RECIPE_IN_PRINTED_ORDER]: 'Receita sob encomenda impressa',
  [commons.IS_TOPPING]: 'É topping',
  [commons.CATEGORIZATION]: 'Categorização',
  [commons.MANDATORY]: 'Obrigatório',
  [commons.EDIT]: 'Editar',
  [commons.ASSIGN_MODIFIER]: 'Atribuir modifiers',
  [commons.ADD_INSTRUCTIONS]: 'Adicionar instruções',
  [commons.UNITS_ABBREVIATION]: 'und',
  [commons.OTHERS]: 'Outros',
  [commons.WEIGHT]: 'Peso',
  [commons.EXIT]: 'Sair',
  [commons.YES]: 'Sim',
  [commons.NO]: 'Não',
  [commons.CURRENT]: 'Atual',
  [commons.NEW]: 'Novo',
  [commons.DIFFERENCE]: 'Diferença',
  [commons.NEW_QUANTITY]: 'Nova quantidade',
  [commons.ULTRAPROCESSED_TAX]: 'Imposto ultraprocessado',
  [commons.ADD_PRODUCT]: 'Adicionar produto',
  [commons.PRODUCTS_THAT_CAN_REPLACE_IT]: 'Produtos que podem substituí-lo',
  [commons.START_TYPING_TO_SEARCH]: 'comece a digitar para pesquisar',
  [commons.NO_MATCH_FOUND]: 'Nenhuma correspondência encontrada',
  [commons.ORIGINAL_QUANTITY]: 'Quantidade original',
  [commons.ORIGINAL_UNIT]: 'Unidade original',
  [commons.ADJUSTED_QUANTITY]: 'Quantidade ajustada',
  [commons.ADJUSTED_UNIT]: 'Unidade ajustada',
  [commons.CHANGES_WILL_BE_SAVED]: 'As alterações serão salvas',
  [commons.CHANGES_WILL_BE_SAVED_IN_ITEM]: 'As alterações feitas no item serão salvas:',
  [commons.NOTIFY_KITCHENS_OF_CHANGE]: 'Você deseja notificar as cozinhas da mudança?',

  [recipeBookType.INSTRUCTIONS_FOR_PREPARATION]: 'Instruções para preparação',
  [recipeBookType.LIFE_CYCLE]: 'Prazo de validade',
  [recipeBookType.ADD_LIFE_CYCLE]: 'Adicionar prazo de validade',
  [recipeBookType.STORAGE_TYPE]: 'Tipo de armazenamento',
  [recipeBookType.RECORD_CARD]: 'Visualizar arquivo',
  [recipeBookType.SEARCH_PRODUCTS]: 'Busque produtos finalizados, produções o insumos',
  [recipeBookType.PRODUCTS_HAS_CONTAINS]: 'produtos que contém',
  [recipeBookType.TO_SEE_PRODUCTS]: 'para ver produtos',
  [recipeBookType.CREATE_NEW_ITEM]: 'Criar novo artigo',
  [recipeBookType.DOWNLOAD_RECIPE]: 'Baixar receitas',
  [recipeBookType.ENABLE_ITEM]: 'Habilitar item',
  [recipeBookType.ENABLE]: 'Habilitar',
  [recipeBookType.CAN_NOT]: 'Não se pode',
  [recipeBookType.CAN_NOT_DISABLE]: 'Não pode ser desativado',
  [recipeBookType.CONFIRM_DISABLE]: 'Tem certeza de que deseja desativar o item?',
  [recipeBookType.CONFIRM_DISABLE_TEXT]: 'Se você desabilitar o item',
  [recipeBookType.CAN_NOT_VIEW]: 'não poderia ser exibido no livro de receitas e nem nas receitas',
  [recipeBookType.I_SURE]: 'Estou certo',
  [recipeBookType.CONFIRM_ENABLE]: 'Tem certeza de que deseja ativar o item?',
  [recipeBookType.CONFIRM_ENABLE_TEXT]: 'Se você ativar o item',
  [recipeBookType.CAN_VIEW]: 'pode ser exibido no livro de receitas e nas receitas',
  [recipeBookType.CREATE_RECIPE]: 'Criar Receita',
  [recipeBookType.HAS_OPERATOR]: 'Preciso de uma pessoa',
  [recipeBookType.MACHINE_REQUIRED]: 'Máquina necessária',
  [recipeBookType.SELECT_MACHINE]: 'Selecione a máquina',
  [recipeBookType.STEP_MACHINE]: 'Máquina de passo',
  [recipeBookType.DETAIL_CHECKPOINT]: 'Detalha o checkpoint',
  [recipeBookType.MESSAGE_SELECT_COUNTRY_PRODUCT_TYPE]:
    'Selecione o país e o tipo de produto para criar a receita',
  [recipeBookType.APPEARANCE]: 'Aparência',
  [recipeBookType.COLOR]: 'Cor',
  [recipeBookType.SMELL]: 'Cheiro',
  [recipeBookType.TASTE]: 'Sabor',
  [recipeBookType.TEXTURE]: 'Textura',
  [recipeBookType.VISCOSITY]: 'Viscosidade',
  [recipeBookType.ENTER_CHARACTERISTICS_OF]: 'Insira as características de',
  [recipeBookType.ENTER_THE]: 'Insira as',
  [recipeBookType.SENSORY_CHARACTERISTICS]: 'Organolépticos',
  [recipeBookType.UNITY_PER_PACKAGE]: 'Unidade por embalagem',
  [recipeBookType.GENERAL]: 'Em geral',
  [recipeBookType.UNITY_PER_WEIGHT]: 'Peso por unidade',
  [recipeBookType.PACKING_TEMPERATURE]: 'Temperatura do produto ao embalar',
  [recipeBookType.PRODUCT_TEMPERATURE]: 'Temperatura do produto',
  [recipeBookType.ENTER_PRODUCT_TEMPERATURE]: 'Insira a temperatura ao embalar',
  [recipeBookType.PROTEIN]: 'Proteina',
  [recipeBookType.VINAIGRETTE]: 'Vinagreta Salsa',
  [recipeBookType.SAUCES]: 'Salsas',
  [recipeBookType.GRAINS]: 'Granos',
  [recipeBookType.BAKING]: 'Postres',
  [recipeBookType.BASE]: 'Base',
  [recipeBookType.DRINK]: 'Bebida',
  [recipeBookType.PREPARED_DRINK]: 'Bebida Preparada',
  [recipeBookType.COVERINGS]: 'Cubiertos',
  [recipeBookType.DONATION]: 'Donacion',
  [recipeBookType.PACKAGING]: 'Empaque',
  [recipeBookType.BAKERY]: 'Panadería',
  [recipeBookType.MAIN_DISH]: 'Plato Fuerte',
  [recipeBookType.SIDES]: 'Porciones Aparte',
  [recipeBookType.DESSERTS]: 'Postres',
  [recipeBookType.SEASONAL_SAUCES]: 'Salsas Estacion',
  [recipeBookType.TOPPING]: 'Topping',
  [recipeBookType.BREADMAKING]: 'Panaderia',
  [recipeBookType.SUBPRODUCTION]: 'Subproduccion',
  [recipeBookType.HOT]: 'Quente',
  [recipeBookType.FRYING]: 'Fritura',
  [recipeBookType.MIX]: 'Mista',
  [recipeBookType.COLD]: 'Fria',
  [recipeBookType.BAKERY_PASTRY_SHOP]: 'Padaria/Pastelaria',
  [recipeBookType.STEP_DURATION]: 'Duração da etapa',
  [recipeBookType.DURATION]: 'Duração',
  [recipeBookType.CP_STATION]: 'Estação cp',
  [recipeBookType.KITCHEN_STATION]: 'Estação de cozinha',
  [recipeBookType.VACUUM_PACKING]: 'Embalagem a vácuo',
  [recipeBookType.SHRINKAGE_PERCENTAGE]: 'Porcentagem de perda',
  [recipeBookType.PERCENTAGE]: 'Porcentagem',
  [recipeBookType.ENTER_THE_PERCENTAGE_OF_SHRINKAGE_FOR_ALL_CITIES]:
    'Insira a porcentagem de redução para todas as cidades',
  [recipeBookType.FACTOR]: 'Fator de conversão',
  [recipeBookType.SELECT_SUPPLY]: 'Selecione o fornecimento',
  [recipeBookType.REPLACE_RECIPE_TITLE]: 'Substituir em receitas',
  [recipeBookType.SELECT_SUPPLY_MODAL_TITLE]: 'Selecionar novo fornecimento',
  [recipeBookType.UPDATE_DIFFERENCE_MESSAGE]: 'A receita será ajustada com {{quantity}} {{unit}}',
  [recipeBookType.UPDATE_FACTOR_MESSAGE]:
    '{{factor}} {{product.unit}} de {{product.name}} equivale a 1 {{selectedProduct.unit}} de {{selectedProduct.name}}',
  [recipeBookType.CALCULATE_WEIGHT_DIVISION]: 'Calcular divisão de peso?',
  [recipeBookType.PRODUCT_SKU_WARNING]:
    'Já existe um produto com o SKU {{currentSku}}, este produto será atribuído ao SKU {{newSku}}.',
  [recipeBookType.PRODUCT_NAME_EXISTS]: 'Já existe um produto com esse nome',
  [recipeBookType.MASSIVE_CHANGES_IN_RECIPES]: 'Grandes mudanças nas receitas',

  [menuType.DEFAULT_ERROR_MSG]: 'Ocorreu um erro ao carregar o menu',
  [menuType.STARTED_LOADING_MENU]: 'Começou a carregar o menu',
  [menuType.UPLOAD_MENU_TO_VIEW]: 'Carregue o menu que pretende ver',
  [menuType.UPLOAD_MENU]: 'Carregar cardápio',

  [menuCreator.ADD_AISLE]: 'Adicionar corredor',
  [menuCreator.PUBLISH_MENU]: 'Menu publicar',
  [menuCreator.SAVE_DRAFT]: 'Salvar rascunho',
  [menuCreator.DRAFT]: 'Rascunho',
  [menuCreator.SETTING]: 'Contexto',
  [menuCreator.MENU]: 'Cardápio',
  [menuCreator.MENUS]: 'Cardápios',
  [menuCreator.SEARCH_MENU]: 'Menu de pesquisa',
  [menuCreator.CREATE_NEW_MENU]: 'Criar novo cardápio',
  [menuCreator.SEARCH_COUNTRY]: 'Pesquisar país',
  [menuCreator.CHOOSE_COUNTRY]: 'Escolha um país para ver os menus desse país',
  [menuCreator.MENU_NAME]: 'Nome do cardápio',
  [menuCreator.BRAND]: 'Marca',
  [menuCreator.STORES]: 'Lojas',
  [menuCreator.LAST_UPDATE]: 'Última atualização',
  [menuCreator.BY]: 'por',
  [menuCreator.ACTIONS]: 'Ações',
  [menuCreator.LIBRARY]: 'Biblioteca de modelos',
  [menuCreator.SAVE_AISLE]: 'Salvar corredor',
  [menuCreator.ADD_MENU_ITEM]: 'Agregar item do menu',
  [menuCreator.AISLE_NAME]: 'Nome do salão',
  [menuCreator.SAVE_MENU_ITEM]: 'Salvar item do menu',
  [menuCreator.SAVE_MODIFIER_TEMPLATE]: 'Salvar como modelo',
  [menuCreator.ADD_MODIFIER_GROUP]: 'Adicionar grupo modificador',
  [menuCreator.PLATFORM_DESCRIPTION]: 'Descrição na plataforma',
  [menuCreator.PLATFORM_NAME]: 'Nome na plataforma',
  [menuCreator.PRICE]: 'Preço',
  [menuCreator.RECIPE_BOOK_NAME]: 'Nome no livro de receitas',
  [menuCreator.CHOOSE_IMAGE]: 'escolha a foto',
  [menuCreator.DELETE_MODAL_TITLE]: 'Deseja excluir este corredor?',
  [menuCreator.DELETE_MODAL_TEXT]: 'Se quiser excluí-lo, clique em “Excluir”',
  [menuCreator.DELETE_MODAL_CANCEL]: 'Cancelar',
  [menuCreator.DELETE_MODAL_ACTION]: 'Eliminar',
  [menuCreator.MENU_MANAGER_TITLE]: 'Gerenciador de menus',
  [menuCreator.CREATE_MENU_MODAL_TITLE]: 'Crie um novo cardápio',
  [menuCreator.COPY_MENU_MODAL_ACTION]: 'Copiar Menu',
  [menuCreator.COPY_MENU_MODAL_LABEL]: 'Digite o nome do menu',
  [menuCreator.COPY_MENU_MODAL_TITLE]: ' Digite o nome da cópia',
  [menuCreator.CREATE_MENU_MODAL_TEXT]: 'Insira os dados necessários para criar o menu',
  [menuCreator.CREATE_MENU_MODAL_NAME]: 'Nome do cardápio',
  [menuCreator.CREATE_MENU_MODAL_CANCEL]: 'Cancelar',
  [menuCreator.CREATE_MENU_MODAL_ACTION]: 'Criar',
  [menuCreator.LOADING_COPY_MENU_TITLE]: 'Algo está cozinhando!',
  [menuCreator.LOADING_COPY_MENU_DETAIL]: 'Seu menu estará completo em alguns segundos',
  [menuCreator.LOADING_COPY_MENU_BUTTON]: 'VOLTAR À TABELA DE MENUS',
  [menuCreator.DELETE_MENU_ITEM_MODAL_TITLE]: ' Deseja excluir este item de menu?',
  [menuCreator.DELETE_MENU_ITEM_MODAL_TEXT]: 'Se quiser excluí-lo, clique em “Excluir”',
  [menuCreator.MENU_ITEMS]: 'Itens do menu',
  [menuCreator.MENU_ITEM]: 'Iten do menu',
  [menuCreator.ENTER_AISLE_NAME]: 'Digite o nome do corredor',
  [menuCreator.SEARCH_SKU_NAME]: 'Pesquise por SKU ou nome',
  [menuCreator.ENTER_PLATFORM_NAME]: 'Digite o nome na plataforma',
  [menuCreator.ENTER_PRICE]: 'Insira o preço',
  [menuCreator.ENTER_DESCRIPTION]: 'Ingresa la descripción',
  [menuCreator.THERE_IS_ALREADY_A_GROUP_WITH_THIS_NAME]: 'Já existe um grupo com este nome',
  [menuCreator.THE_VALUE_CANNOT_BE_NEGATIVE]: 'O valor não pode ser negativo',
  [menuCreator.MODIFIER_GROUP_NOT_FOUND]: 'Grupo modificador não encontrado',
  [menuCreator.ENTER_A_NAME_FOR_THE_NEW_GROUP]: 'Insira um nome para o novo grupo modificador',
  [menuCreator.FILL_IN_THE_REQUIRED_DATA_IN_THE_MODIFIERS]:
    'Preencha os dados necessários nos modificadores',
  [menuCreator.A_PRODUCT_WITH_THIS_SKU_ALREADY_EXISTS_IN_THE_GROUP]:
    'Já existe um produto com este SKU no grupo',
  [menuCreator.MODIFIER_GROUP]: 'Grupo modificador',
  [menuCreator.DELETE_MODIFIER_GROUP]: 'Deseja remover este grupo modificador?',
  [menuCreator.DELETE_MODIFIER]: '¿Deseas eliminar este modificador?',
  [menuCreator.MODIFIER_GROUP_NAME]: 'Nome do grupo modificador',
  [menuCreator.ENTER_THE_GROUP_NAME]: 'Ingresa el nombre del grupo',
  [menuCreator.MINIMUM]: 'Mínimo',
  [menuCreator.MAXIMUM]: 'Máximo',
  [menuCreator.OPTIONAL]: 'Opcional',
  [menuCreator.PREVIOUSLY_CREATED]: 'criado anteriormente',
  [menuCreator.DRAG_MODIFIERS]: 'Arraste os modificadores para esta seção',
  [menuCreator.CREATE_A_NEW_MODIFIER]: 'Crie um novo modificador',
  [menuCreator.ADD_MODIFIERS]: 'Adicionar modificadores',
  [menuCreator.SAVE_GROUP_MODIFIERS]: 'Salvar modificadores de grupo',
  [menuCreator.EDIT_GROUP_MODIFIERS]: 'Editar grupo modificador',
  [menuCreator.MODIFIER_GROUPS]: 'Grupos modificadores',
  [menuCreator.DRAG_MODIFIERS_GROUPS]: 'Arraste os grupos modificadores para esta seção',
  [menuCreator.CREATE_A_NEW_GROUP]: 'Crie um novo grupo',
  [menuCreator.ADD_MODIFIER_GROUPS]: 'Adicionar grupos modificadores',
  [menuCreator.ADD_THE_GROUPS]: 'Adicionar grupos',
  [menuCreator.SHOW_PUBLISHED]: 'Veja publicado',
  [menuCreator.MODIFIERS]: 'Modificadores',
  [menuCreator.ADD_THE_MODIFIERS]: 'Agrega los Modificadores',
  [menuCreator.DESCRIPTION]: 'Descrição',
  [menuCreator.EDIT_MENU]: 'Editar cardápio',
  [menuCreator.VIEW_MENU]: 'Ver cardápio',
  [menuCreator.SAVE_SETTINGS]: 'Salvar configurações',
  [menuCreator.NONE_RESPONSE]: 'Sem resposta',
  [menuCreator.SEND]: 'Enviado',
  [menuCreator.REFUSED]: 'Recusou',
  [menuCreator.PUBLISHED]: 'Publicados',
  [menuCreator.DATE_TEXT_DRAFT]: 'Última modificação',
  [menuCreator.RESTORE_PUBLISHED]: 'Redefinir publicado',
  [menuCreator.RESTORE_MODAL_TITLE]: 'Deseja excluir o rascunho e restaurar o publicado??',
  [menuCreator.RESTORE_MODAL_TEXT]:
    'Concordar em redefinir removerá quaisquer alterações feitas no rascunho atual',
  [menuCreator.MENU_WITH_CHANGES]: 'Menu com alterações não publicadas',
  [menuCreator.REASSIGN_STORE]: 'Reatribuir loja',
  [menuCreator.SCHEDULE]: 'Agendar',
  [menuCreator.STORE_SCHEDULE]: 'Horário de funcionamento',
  [menuCreator.MONDAY]: 'Segunda-feira',
  [menuCreator.TUESDAY]: 'Terça-feira',
  [menuCreator.WEDNESDAY]: 'Quarta-feira',
  [menuCreator.THURSDAY]: 'Quarta-feira',
  [menuCreator.FRIDAY]: 'Sexta-feira',
  [menuCreator.SATURDAY]: 'Sábado',
  [menuCreator.SUNDAY]: 'Domingo',
  [menuCreator.MORE_INFO_ONE]: 'Se desejar alterar algum horário, entre em contato',
  [menuCreator.MORE_INFO_TWO]: 'com a equipe de tech ops',
  [menuCreator.DELETE_MODIFIER_GROUPS]: 'Excluir grupo modificador',
  [menuCreator.MODIFIER_GROUPS_SINGULAR]: 'Grupo modificador',
  [menuCreator.CREATE_A_NEW_GROUP_OF_MODIFIERS]: 'Crie um novo grupo de modificadores',
  [menuCreator.EDIT_MODIFIER]: 'Editar modificador',
  [menuCreator.DELETE_MODIFIERS]: 'Remover modificador',
  [menuCreator.SAVE_MODIFIER]: 'Guardar modificador',
  [menuCreator.ALERT_MAX_VALUE]:
    'O máximo dos modificadores é maior que o valor máximo do grupo modificador',
  [menuCreator.INTERNAL_NAME_MODIFIER_GROUP]: 'Nome interno do grupo modificador',
  [menuCreator.INTERNAL_NAME]: 'Ingresa el nombre interno',
  [menuCreator.FIND_THE_MODIFIER_GROUP]: 'Encontre o grupo modificador',
  [menuCreator.WRITE_THE_NAME_OF_THE_GROUP]: 'Escreva o nome do grupo',
  [menuCreator.FIND_THE_MODIFIERS]: 'Encontre os modificadores',
  [menuCreator.WRITE_THE_NAME_OF_THE_MODIFIER]: 'Escreva o nome do modificador',
  [menuCreator.EDIT_MENU_ITEM]: 'Editar item do menu',
  [menuCreator.EDIT_AISLE]: ' Editar Corredor',
  [menuCreator.SELECTED]: 'Você selecionou',
  [menuCreator.ALERT_NO_CHILDREN]: 'Adicione pelo menos um modificador',
  [menuCreator.MAX]: 'Max',
  [menuCreator.MIN]: 'Min',
  [menuCreator.THERE_ARE_NO_RESULTS_FOR]: 'Não há resultados para',
  [menuCreator.CITY_ZONE]: 'Cidade / Zona',
  [menuCreator.STAY_IN_MENU]: 'Fique no cardápio',
  [menuCreator.BACK_TO_BEGINNING]: 'Voltar ao início',
  [menuCreator.THE_MENU]: 'O menu',
  [menuCreator.SAVED_MENU_TITLE]: 'foi salvo como rascunho',
  [menuCreator.AFTER_PUBLISH_MENU_TITLE]: 'foi publicado',
  [menuCreator.SAVED_MENU_TEXT]: 'Verifique o status do menu na tabela principal do gerenciador',
  [menuCreator.PUBLISH_MENU_TEXT]: 'Ao clicar em “Publicar” este novo menu será exibido nas lojas',
  [menuCreator.ALL_GROUPS]: 'Todos os grupos',
  [menuCreator.ALL_BRANDS]: 'Todas as marcas',
  [menuCreator.ALL_CATEGORIES]: 'Todas as categorias',
  [menuCreator.SELECT_COUNTRY]: 'Selecione um país',
  [menuCreator.PUBLISH_MENU_TITLE]: 'Você deseja publicar, {{menuName}}?',
  [menuCreator.PRICE_CANNOT_BE_0]: 'O preço não pode ser 0',
  [menuCreator.CANNOT_REPEAT_A_MENU_ITEM]: 'Você não pode repetir um item de menu no menu',
  [menuCreator.EXCEEDED_THE_MAXIMUM_NUMBER_OF_CHARACTERS_FOR_RAPPI]:
    'Excedeu o número máximo de caracteres do Rappi',
  [menuCreator.MINIMUM_CAN_BE_3_CHARACTERS]: 'O mínimo pode ser de 3 caracteres',
  [menuCreator.YOU_HAVE_EXCEEDED_THE_MAXIMUM_NUMBER_OF_CHARACTERS_ALLOWED]:
    'Você excedeu o número máximo de caracteres permitidos',
  [menuCreator.AND_YOU_ARE_AT]: 'e você está em',
  [menuCreator.CHARACTERS_REACHING_IFOOD_LIMIT]: 'personagens atingindo limite do iFood',
  [menuCreator.MISSING]: 'Faltando',
  [menuCreator.CHARACTERS_TO_REACH_THE_MAXIMUM_ALLOWED]:
    'caracteres para atingir o máximo permitido',
  [menuCreator.THERE_IS_ALREADY_A_MENU_WITH_THAT_NAME]: 'Já existe um menu com esse nome',
  [menuCreator.YOU_HAVE_NOT_SAVED_ALL_THE_MENU_ITEMS]: 'Você não salvou todos os itens do menu',
  [menuCreator.YOU_HAVE_NOT_SAVED_ALL_MODIFIER_GROUPS_IN_THE_MENU_ITEM]:
    'Você não salvou todos os grupos modificadores no item de menu',
  [menuCreator.MENU_COPIED_SUCCESSFULLY]: 'Menu copiado satisfatoriamente',
  [menuCreator.THIS_MENU_COULD_NOT_BE_ASSIGNED_TO_STORES]:
    'Este menu não pôde ser atribuído a lojas',
  [menuCreator.NUMBER_OF_CHARACTERS_DOES_NOT_REACH_THE_MINIMUM]:
    'O número de caracteres não atinge o mínimo',
  [menuCreator.NUMBER_OF_CHARACTERS_EXCEEDS_THE_MAXIMUM]: 'O número de caracteres excede o máximo',
  [menuCreator.IN_THE_HALLWAY_NAME_FIELD]: 'No campo do nome do corredor',
  [menuCreator.IN_THE_MODIFIER_GROUP_NAME_FIELD]: 'No campo de nome do grupo modificador',
  [menuCreator.IN_THE_MENU_ITEM_DESCRIPTION]: 'Na descrição do item de menu',
  [menuCreator.IN_THE_MODIFIER_DESCRIPTION_FIELD]: 'No campo de descrição do modificador',
  [menuCreator.IN_THE_NAME_OF_THE_MENU_ITEM]: 'No nome do item de menu',
  [menuCreator.IN_THE_MODIFIER_NAME_FIELD]: 'No campo do nome do modificador',
  [menuCreator.THE_NUMBER_OF_DIGITS_IN_THE_PRICE_EXCEEDS_THE_MAXIMUM_ALLOWED]:
    'O número de dígitos no preço excede o máximo permitido',
  [menuCreator.IN_THE_PRICE_FIELD_OF_THE_MENU_ITEM]: 'no campo de preço do item do menu',
  [menuCreator.ALERT_VALUE_OF_THE_MODIFIERS]:
    'O valor máximo dos modificadores não pode ser maior que o valor máximo do grupo modificador',
  [menuCreator.AISLE]: 'Corredor',
  [menuCreator.PRODUCT]: 'Produto',
  [menuCreator.IN_THE_AISLE]: 'No corredor',
  [menuCreator.THERE_IS_ALREADY_A_HALLWAY_WITH_THAT_NAME]: 'Já existe um corredor com esse nome',
  [menuCreator.CANNOT_SAVE_MODIFIER_GROUP]: 'Não é possível salvar o grupo modificador',
  [menuCreator.CANNOT_SAVE_MODIFIER_GROUP_DETAIL]:
    'Já existe um grupo modificador com esse nome, altere o nome se ainda quiser salvá-lo.',
  [menuCreator.CANNOT_SAVE_MODIFIER]: 'Não é possível salvar modificador',
  [menuCreator.CANNOT_SAVE_MODIFIER_DETAIL]:
    'Já existe um modificador com esse nome, altere o nome se ainda quiser salvá-lo.',
  [menuCreator.SAVED_COMPONENT_FRAME]: ' foi salvo como modelo',
  [menuCreator.UPDATED_COMPONENT_FRAME]: 'foi atualizado',
  [menuCreator.SAVED_MODIFIER_GROUP_DETAIL]:
    'Agora você pode consultar o grupo de modificadores salvos na biblioteca de modelos.',
  [menuCreator.SAVED_MODIFIER_DETAIL]:
    'Agora você pode consultar o modificador salvo na biblioteca de modelos.',
  [menuCreator.RETURN_TO_EDIT]: 'Voltar para editar',
  [menuCreator.SEE_LESS]: 'Ver mais',
  [menuCreator.SEE_MORE]: 'Ver menos',
  [menuCreator.MINIMUM_VALUE_CANNOT_BE_GREATER_THAN_THE_MAXIMUM_VALUE]:
    'O valor mínimo não pode ser maior que o valor máximo',
  [menuCreator.SAVE_NAME]: 'Salvar nome',
  [menuCreator.WRITE_THE_NAME_OF_THE_MENU_ITEM]: 'Escreva o nome do menu item',
  [menuCreator.FIND_THE_MENU_ITEMS]: 'Encontre os menu item',
  [menuCreator.CREATE_A_MENU_ITEM]: 'Crie um novo menu item',
  [menuCreator.YOU_CAN_NOW_CONSULT_THE_SAVED_MENU_ITEM_IN_THE_TEMPLATE_LIBRARY]:
    'Agora você pode consultar o item de menu salvo na biblioteca de modelos.',
  [menuCreator.CANNOT_SAVE_THIS_MENU_ITEM]: 'Não é possível salvar este item de menu',
  [menuCreator.TEMPLATE_FOR_THAT_MENU_ITEM_ALREADY_EXISTS]:
    'Já existe um modelo para esse item de menu, altere o produto se ainda quiser salvá-lo.',
  [menuCreator.ADD_MENU_ITEMS]: 'Adicionar itens de menu',
  [menuCreator.DRAG_MENU_ITEMS]: 'Arraste os menu items para esta seção',
  [menuCreator.COMPLETE_MENU_ITEM]: 'Salve o item de menu antes de continuar',
  [menuCreator.COLLAPSE_ALL_AISLES]: 'Recolher todos os corredores',
  [menuCreator.EXPAND_ALL_AISLES]: 'Expanda todos os corredores',
  [menuCreator.CANNOT_BE_DELETED]: 'Não é possível excluir',
  [menuCreator.DELETE_MENU]: 'Excluir menu',
  [menuCreator.ASSIGNED_MENU]: 'Este menu não pode ser excluído porque está atribuído às lojas:',
  [menuCreator.WANT_TO_DELETE_THIS_MENU]: 'Tem certeza de que deseja excluir este menu?',
  [menuCreator.SELECT_A_STORE]:
    'Selecione pelo menos uma loja e salve as configurações para publicar o menu',
  [menuCreator.SAVE_TEMPLATE]: 'Salvar {{type}} como modelo',

  [categorization.CATEGORIZATION_BY_CITIES]: 'Categorização por cidades',

  [menuUploader.PERFECT_FILE]: '¡O arquivo está perfeito!',
  [menuUploader.CLICK_TO_UPLOAD]: 'Clique em "CARREGAR MENU"',
  [menuUploader.REVIEWING_MENU]: 'Estamos revisando o cardápio',

  [measureUnits.UN]: 'Unidade',
  [measureUnits.G]: 'Gramas',
  [measureUnits.ML]: 'Mililitros',
  [measureUnits.GR]: 'Gramas',
  [measureUnits.KG]: 'Quilogramas',

  [productForm.DRAFT]: 'Rascunho',
  [productForm.PUBLISHED]: 'Publicados',
  [productForm.SAVE_DRAFT]: 'Salvar rascunho',
  [productForm.SAVE_CHANGES]: 'Salvar alterações',
  [productForm.PUBLISH_CHANGES]: 'Publicar mudanças',
  [productForm.CREATED]: 'Criado',
  [productForm.EDITED]: 'Editado',
  [productForm.PUBLISH]: 'Publicar',
  [productForm.NCM_CODE_REQUIRED]: 'Código ncm é obrigatório',
  [productForm.COMPLETE_FORM]: 'Insira todos os dados',
  [productForm.AVOID_NEGATIVE_INVETORY]: 'Evite estoque negativo',
  [productForm.SHOW_RECIPE_ON_STATION]: 'Receita visível na estação',

  // [timeUnit.EMPTY]: '',
  [timeUnit.HOUR]: 'Horas',
  [timeUnit.DAY]: 'Dias',
  [timeUnit.WEEK]: 'Semanas',
  // [timeUnit.MONTH]: 'Mêses',

  // [storageUnit.EMPTY]: '',
  [storageUnit.FREEZING]: 'Congelamento ( -18ºC )',
  [storageUnit.REFRIGERATION]: 'Refrigeração (1 ºC - 5ºC)',
  [storageUnit.ROOM_TEMPERATURE]: 'Temperatura Ambiente (15ºC - 30ºC)',

  [machineList.STOVE]: 'Fogão',
  [machineList.UNOX]: 'Unox',
  [machineList.GRIDDLE]: 'Chapa',
  [machineList.DEEP_FRYER]: 'Fritadeira',
  [machineList.PIZZA_OVEN]: 'Forno de Pizza',
  [machineList.SEALER]: 'Seladora',
  [machineList.INDUSTRIAL_BLENDER]: 'Liquidificador Industrial',
  [machineList.MIXER]: 'Batedeira',
  [machineList.DOUGH_MIXER]: 'Amassadeira',
  [machineList.IMMERSION_BLENDER]: 'Mixer de Imersão',
  [machineList.ELECTRIC_MILL]: 'Moinho Elétrico',
  [machineList.LANG_CINNABON_OVEN]: 'Forno Lang Cinnabon',
  [machineList.COOKING_KETTLE]: 'Caldeirão',
  [machineList.FERMENTER]: 'Fermentador',
  [machineList.DOUGH_SHEETER]: 'Laminadora de Massa',
  [machineList.MIXER_60_LT]: 'Batedeira 60Lt',
  [machineList.BLAST_CHILLER]: 'Abatedor',
  [machineList.COOLING]: 'Refrigeração',
  [machineList.MISE_EN_PLACE]: 'Mise en Place',
  [machineList.PROCESS]: 'Processo',
  [machineList.PACKAGING]: 'Embalagem',

  [info.BRAND]:
    'Aqui você confere a lista de marcas e em quais plataformas esse produto ou insumo é encontrado.',
  [info.CATEGORIZATION]:
    'A categorização indica quais suprimentos são mais essenciais em uma cozinha. Exemplo: · Tipo AA essencial numa cozinha porque nos afeta não vender produtos acabados deste tipo. · O tipo C é um insumo que não desativa tantos produtos acabados, afetando apenas pratos com baixo volume e frequência de vendas.',
  [info.EDITED]: 'Foi editado com sucesso',
  [info.HIDDEN]: 'A cozinha não poderá visualizar',
  [info.SAVED]: 'Suas alterações no rascunho foram salvas',
  [info.PUBLISH]: 'Tem certeza de que deseja publicar as alterações?',
  [info.WARNING_PUBLISH]:
    'Após publicar você não poderá editar o SKU, recomendamos que deixe em rascunho enquanto o catálogo faz a alteração no Intuipos.',
  [info.PRODUCT_TYPE]: 'Tipo de produto acabado.',
  [info.STATION]: 'Estação onde deverá sair o pedido do produto.',
  [info.CATALOG]: '[Somente para Catálogo]',
  [info.CATEGORY]: 'Categoria necessária para contabilidade.',
  [info.HIDE_PRODUCT]: 'Selecione-o se desejar que este produto final fique oculto por padrão.',
  [info.HIDE_TOPPING]:
    'Selecione-o se quiser que apareça por padrão como uma topping vinculada a um prato principal.',
  [info.LOADING_PRODUCTS]: 'Carregando produtos',
  [info.LOADING_SUPPLIES]: 'Carregando suprimentos',
  [info.SELECT_BRAND_AND_PLATFORM]:
    'Por favor, selecione uma marca e uma plataforma para continuar',
  [info.PRODUCTS_NOT_FOUND]: 'Nenhum produto encontrado para',
  [info.EMPTY_RECIPE]: 'Receita vazia',
  [info.INCORRECT_AMOUNT]: 'Quantia incorreto',
  [info.ADD_AT_LEAST_ONE_INGREDIENT]: 'Você deve adicionar pelo menos um ingrediente na receita',
  [info.IS_REQUIRED]: ' é obrigatório',
  [info.QUANTITY_LESS_THAN_OR_EQUAL_TO_0]:
    'Nenhum item da receita pode ter quantidade menor ou igual a 0',
  [info.ORGANOLEPTIC_MANDATORY]: 'Todos os testes organolépticos são obrigatórios',
  [info.SHELFLIFE_MANDATORY]: 'Insira pelo menos um elemento na vida útil',
  [info.SHOW_AS_COMBO]: 'Será exibido como combo',
  [info.IS_VARIABLE_COMBO]: 'É um combo variável',
  [info.RECIPE_IN_PRINTED_ORDER]: 'A receita será impressa no pedido',

  [productGroup.PRODUCTION]: 'Produção',
  [productGroup.FINISHED_PRODUCT]: 'Prodtermin',
  [productGroup.SUPPLY]: 'Insumo',
  [productGroup.COMBO]: 'Combo',
  [productGroup.GROCERY]: 'Mercearia',
  [productGroup.DRINK]: 'Bebidas',
  [productGroup.ALCOHOLIC_DRINK]: 'Bebidas alcoólicas',
  [productGroup.MEAT]: 'Carnes',
  [productGroup.BEER]: 'Cervejas',
  [productGroup.CIGARETTES]: 'Cigarros',
  [productGroup.UNKNOWN]: 'Um estranho',
  [productGroup.ADJUSTABLE_PACKAGING]: 'Embalagens ajustável',
  [productGroup.PACKAGING]: 'Embalagens',
  [productGroup.FRUITS]: 'Frutas',
  [productGroup.DAIRY]: 'Laticíno',
  [productGroup.MAINTENANCE]: 'Manutenção',
  [productGroup.BAKERY]: 'Padaria',
  [productGroup.UTENSILS]: 'Utensílios',
  [productGroup.CLEANING]: 'Limpeza',
  [productGroup.CONSUMB]: 'Consumiveis',
  [productGroup.MENUITEMS]: 'Itens do cardapio',
  [productGroup.RAWMATRLS]: 'Materiais primos',
  [productGroup.SEMIFINISHED]: 'Semifinal',
  [productGroup.STATIONERY]: 'Material de escrito',

  [productSearchOptions.ALL]: 'Todos os casos com',
  [productSearchOptions.RECIPE]: 'Contêm na receita',

  [cpStationOptoins.HOT]: 'Quente',
  [cpStationOptoins.FRYING]: 'Fritura',
  [cpStationOptoins.MIX]: 'Misto',
  [cpStationOptoins.COLD]: 'Fria',
  [cpStationOptoins.BAKERYPASTRY]: 'Padaria e Confeitaria',
  [cpStationOptoins.PACKAGING]: 'Embalagem',
};
