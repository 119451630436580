import { ApiVersion, getHttp } from '../../../utils/http';
import { Company } from '../entities/Company';

export const getCompanyByCode = async (code: string): Promise<Company | undefined> => {
  try {
    const response = await getHttp(
      process.env.REACT_APP_AVATAR_URL ?? '',
      ApiVersion.V1,
      `company/by-code/${code}`,
    );
    const result = await response.json();
    return result as Company;
  } catch (ex: any) {
    console.error(`Error getCompanyByCode | ${ex.message}`);
    return undefined;
  }
};
