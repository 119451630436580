import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Alert,
  BrandsState,
  CitiesState,
  CompanyState,
  CountriesState,
  defaultData,
  defaultDataSingle,
  initialState,
  PlatformsState,
} from './state';

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    showAlert: (state, alert: PayloadAction<Alert>) => {
      state.alert = { ...alert.payload, show: true };
    },
    showSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = '';
      state.canSearchOnTopBar = true;
    },
    hideSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = '';
      state.canSearchOnTopBar = false;
    },
    setSearchOnTopBar: (state, action: PayloadAction<string>) => {
      state.textSearchOnTopBar = action.payload;
    },
    clearSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = '';
    },
    hideAlert: (state) => {
      state.alert = { show: false };
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    clearTitle: (state) => {
      state.title = null;
    },
    setCities: (state, action: PayloadAction<CitiesState>) => {
      state.cities = action.payload;
    },
    clearCities: (state) => {
      state.cities = defaultData as CitiesState;
    },
    setBrands: (state, action: PayloadAction<BrandsState>) => {
      state.brands = action.payload;
    },
    clearBrands: (state) => {
      state.brands = defaultData as BrandsState;
    },
    setCountries: (state, action: PayloadAction<CountriesState>) => {
      state.countries = action.payload;
    },
    clearCountries: (state) => {
      state.countries = defaultData as CountriesState;
    },
    setPlatforms: (state, action: PayloadAction<PlatformsState>) => {
      state.platforms = action.payload;
    },
    clearPlatforms: (state) => {
      state.platforms = defaultData as PlatformsState;
    },
    setCompany: (state, action: PayloadAction<CompanyState>) => {
      state.company = action.payload;
    },
    clearCompany: (state) => {
      state.company = defaultDataSingle as CompanyState;
    },
  },
});

export const {
  showAlert,
  hideAlert,
  setTitle,
  clearTitle,
  showSearchOnTopBar,
  hideSearchOnTopBar,
  setSearchOnTopBar,
  clearSearchOnTopBar,
  setCities,
  clearCities,
  setCountries,
  clearCountries,
  setBrands,
  clearBrands,
  setPlatforms,
  clearPlatforms,
  setCompany,
  clearCompany,
} = globalSlice.actions;
export const { reducer } = globalSlice;

export default globalSlice;
